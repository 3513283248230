<mat-toolbar color="primary">

  <div class="flex-row">
    <a mat-button class="header-title" [routerLink]="['welcome']" i18n="@@main.title">GeoShop Title</a>
    <mat-divider *ngIf="subTitle" vertical="true" color="accent"></mat-divider>
    <span class="header-subtitle">{{subTitle}}</span>
  </div>


  <div class="right-container">

    <button mat-icon-button [matMenuTriggerFor]="helpOverlay">
      <mat-icon>contact_support</mat-icon>
    </button>
    <mat-menu #helpOverlay="matMenu" class="overlay-menu-container">
      <gs2-help-overlay></gs2-help-overlay>
    </mat-menu>

    <button mat-icon-button [matMenuTriggerFor]="accountOverlay">
      <mat-icon>account_circle</mat-icon>
    </button>
    <mat-menu #accountOverlay="matMenu" class="overlay-menu-container">
      <gs2-account-overlay></gs2-account-overlay>
    </mat-menu>

    <mat-divider vertical="true" color="accent"></mat-divider>

    <button mat-icon-button
            [disabled]="!(order$|async)?.url && (numberOfItemInTheCart$|async) === 0"
            [matMenuTriggerFor]="cartOverlay">
      <mat-icon
        [matBadge]="(numberOfItemInTheCart$|async)  ? (numberOfItemInTheCart$|async)?.toString() : undefined"
        matBadgePosition="before" matBadgeColor="warn"
        matBadgeSize="small">shopping_cart
      </mat-icon>
    </button>
    <mat-menu #cartOverlay="matMenu" class="overlay-menu-container">
      <gs2-cart-overlay></gs2-cart-overlay>
    </mat-menu>

  </div>
</mat-toolbar>

<main>
  <router-outlet></router-outlet>
</main>

