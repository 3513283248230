<ng-container *ngIf="isLoggedIn$|async">
  <div class="flex-row name-container">
    <mat-icon>face</mat-icon>
    <span>{{(user$|async) && (user$|async)?.first_name && (user$|async)?.last_name ?
      (user$|async)?.first_name + ' ' + (user$|async)?.last_name :
      (user$|async)?.username}}</span>
  </div>

  <button mat-button color="primary" [routerLink]="['/account/orders']">
    <span class="flex-row">
      <mat-icon>receipt</mat-icon>
      <span i18n="@@account.order">Mes Commandes</span>
    </span>
  </button>
  <button mat-button color="primary" [routerLink]="['/account/profile']">
    <span class="flex-row">
      <mat-icon>perm_identity</mat-icon>
      <span i18n="@@account.profile">Mon profil</span>
    </span>
  </button>
  <button mat-button color="primary" (click)="logout()">
    <span class="flex-row">
      <mat-icon>exit_to_app</mat-icon>
      <span i18n="@@account.logout">Déconnexion</span>
    </span>
  </button>

</ng-container>

<ng-container *ngIf="!(isLoggedIn$|async)">    
  <div class="flex-row px-16">
    <mat-icon class="overflow">info</mat-icon>
    <span i18n="@@account.auth_required">Pour commander vous devez obligatoirement vous authentifier.</span>
  </div>

  <button mat-button color="primary" [routerLink]="['auth/login']">
    <span class="flex-row">
      <mat-icon>input</mat-icon>
      <span i18n="@@account.log_in">S'authentifier</span>
    </span>
  </button>
  <button mat-button color="primary" [routerLink]="['auth/register']">
    <span class="flex-row">
      <mat-icon>person_add</mat-icon>
      <span i18n="@@account.create_account">Créer un compte</span>
    </span>
  </button>
</ng-container>
