<button color="primary" class="close-button" mat-mini-fab (click)="dialogRef.close()"><mat-icon>close</mat-icon></button>

<div class="flex-row">
  <mat-card class="contacts-container" *ngFor="let person of data.contact_persons">
      <mat-card-title>{{person.metadata_role}}</mat-card-title>
    <mat-card-content>
      <div class="person-container">
        <div class="flex-row">
  
          <div class="flex-column">
            <span class="line-height-36">{{person.contact_person.first_name}} {{person.contact_person.last_name}}</span>
          </div>
  
          <div class="flex-column">
            <a class="flex-row" [href]="'mailto:'+person.contact_person.email" mat-button color="primary">
              <mat-icon class="m-r-5" i18n="@@main.email">email</mat-icon>
              <span>{{person.contact_person.email}}</span>
            </a>
            <a class="flex-row" [href]="'tel:'+person.contact_person.phone" mat-button color="primary">
              <mat-icon class="m-r-5" i18n="@@main.phone">phone</mat-icon>
              <span>{{person.contact_person.phone}}</span>
            </a>
          </div>
  
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<mat-card class="metadata-main-container">
  <mat-card-header>
    <div mat-card-avatar class="header-image"
         [style.backgroundImage]="'url(' + mediaUrl + data.image_link + ')'"></div>
    <mat-card-title-group>
      <mat-card-title>{{data.name}}</mat-card-title>
      <mat-card-subtitle i18n="@@dialog.metadata">Métadonnée</mat-card-subtitle>
    </mat-card-title-group>
    <div class="link-button" *ngIf="data.documents?.length > 0">
      <a mat-raised-button href="{{ data.documents[0].link }}" target="_blank">{{ data.documents[0].name }}</a>
    </div>
  </mat-card-header>
  <mat-card-content>

    <div class="image-center-container">
      <img [src]="mediaUrl + data.image_link" [alt]="data.name">
    </div>


    <mat-accordion>
      <mat-expansion-panel expanded="true" *ngIf="data.description_long">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@dialog.description">Description</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

        <p class="long-description" [innerHTML]="data.description_long | safeHtml"></p>
        <p *ngIf="data.data_last_update_date" i18n="@@main.last_update">Dernière mise à jour des données: {{ data.data_last_update_date }}</p>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="data.legend_link && data.legend_link?.indexOf('http') > -1">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@dialog.legend">Légende</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>

        <img [src]="data.legend_link" alt="Légende">
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="data.documents?.length > 1">
        <mat-expansion-panel-header>
          <mat-panel-title i18n="@@dialog.know_more">En savoir plus...</mat-panel-title>
          <mat-panel-description></mat-panel-description>
        </mat-expansion-panel-header>
        <mat-list>
          <div *ngFor="let document of data.documents; let first = first;">
            <mat-list-item *ngIf="!first">
              <mat-icon mat-list-icon color="primary">open_in_new</mat-icon>
              <a mat-button href="{{ document.link }}" target="_blank" color="primary">{{ document.name }}</a>
            </mat-list-item>
          </div>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>

  </mat-card-content>
  <mat-card-actions>
    <a *ngIf="data.geocat_link.indexOf('http') > -1" [href]="data.geocat_link" target="_blank" mat-button
       color="primary" i18n="@@dialog.geocat_link">
       Ouvrir géocat
      <mat-icon>open_in_new</mat-icon>
    </a>
  </mat-card-actions>
</mat-card>
