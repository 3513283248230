<div class="flex-column items-container" *ngFor="let product of products">
  <div class="flex-row padding-horizontal-15 justify-content-space-between">
    <span class="text-ellipsis-2" [matTooltip]="product.label">{{product.label}}</span>

    <button mat-icon-button color="primary" *ngIf="product.metadata" (click)="openMetadata(product)">
      <mat-icon>description</mat-icon>
    </button>
    <button mat-icon-button color="accent" (click)="removeProduct(product.label)">
      <mat-icon>delete</mat-icon>
    </button>
  </div>

  <mat-divider></mat-divider>
</div>

<button mat-raised-button color="accent" class="action-button" (click)="deleteCart()" i18n="@@cart.empty">
  Vider le panier
</button>

<button [disabled]="order && !order.geom" mat-raised-button color="primary" class="action-button"
        (click)="naviguateToNewOrder()" i18n="@@cart.order_continue">
  Poursuivre la commande...
</button>

<div *ngIf="order && !order?.geom" class="flex-row warning-container">
  <mat-icon>warning</mat-icon>
  <span i18n="@@cart.select_perimeter">Vous devez sélectionner un périmètre avant de poursuivre la commande.</span>
</div>
